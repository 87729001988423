export const stripeCurrencies = [
    { currency: 'USD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'AED', currency_symbol: 'د.إ', multiplier: 100, type: 'STRIPE' },
    { currency: 'AFN', currency_symbol: '؋', multiplier: 100, type: 'STRIPE' },
    { currency: 'ALL', currency_symbol: 'L', multiplier: 100, type: 'STRIPE' },
    { currency: 'AMD', currency_symbol: '֏', multiplier: 100, type: 'STRIPE' },
    { currency: 'ANG', currency_symbol: 'ƒ', multiplier: 100, type: 'STRIPE' },
    { currency: 'AOA', currency_symbol: 'Kz', multiplier: 100, type: 'STRIPE' },
    { currency: 'ARS', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'AUD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'AWG', currency_symbol: 'ƒ', multiplier: 100, type: 'STRIPE' },
    { currency: 'AZN', currency_symbol: '₼', multiplier: 100, type: 'STRIPE' },
    { currency: 'BAM', currency_symbol: 'BAM', multiplier: 100, type: 'STRIPE' },
    { currency: 'BBD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'BDT', currency_symbol: '৳', multiplier: 100, type: 'STRIPE' },
    { currency: 'BGN', currency_symbol: 'лв', multiplier: 100, type: 'STRIPE' },
    { currency: 'BIF', currency_symbol: 'Fr', multiplier: 1, type: 'STRIPE' },
    { currency: 'BMD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'BND', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'BOB', currency_symbol: 'Bs.', multiplier: 100, type: 'STRIPE' },
    { currency: 'BRL', currency_symbol: 'R$', multiplier: 100, type: 'STRIPE' },
    { currency: 'BSD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'BWP', currency_symbol: 'P', multiplier: 100, type: 'STRIPE' },
    { currency: 'BZD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'CAD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'CDF', currency_symbol: 'Fr', multiplier: 100, type: 'STRIPE' },
    { currency: 'CHF', currency_symbol: 'Fr', multiplier: 100, type: 'STRIPE' },
    { currency: 'CLP', currency_symbol: '$', multiplier: 1, type: 'STRIPE' },
    { currency: 'CNY', currency_symbol: '¥', multiplier: 100, type: 'STRIPE' },
    { currency: 'COP', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'CRC', currency_symbol: '₡', multiplier: 100, type: 'STRIPE' },
    { currency: 'CVE', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'CZK', currency_symbol: 'Kč', multiplier: 100, type: 'STRIPE' },
    { currency: 'DJF', currency_symbol: 'Fr', multiplier: 1, type: 'STRIPE' },
    { currency: 'DKK', currency_symbol: 'kr', multiplier: 100, type: 'STRIPE' },
    { currency: 'DOP', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'DZD', currency_symbol: 'د.ج', multiplier: 100, type: 'STRIPE' },
    { currency: 'EGP', currency_symbol: '£', multiplier: 100, type: 'STRIPE' },
    { currency: 'ETB', currency_symbol: 'Br', multiplier: 100, type: 'STRIPE' },
    { currency: 'EUR', currency_symbol: '€', multiplier: 100, type: 'STRIPE' },
    { currency: 'FJD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'FKP', currency_symbol: '£', multiplier: 100, type: 'STRIPE' },
    { currency: 'GBP', currency_symbol: '£', multiplier: 100, type: 'STRIPE' },
    { currency: 'GEL', currency_symbol: '₾', multiplier: 100, type: 'STRIPE' },
    { currency: 'GIP', currency_symbol: '£', multiplier: 100, type: 'STRIPE' },
    { currency: 'GMD', currency_symbol: 'D', multiplier: 100, type: 'STRIPE' },
    { currency: 'GNF', currency_symbol: 'Fr', multiplier: 1, type: 'STRIPE' },
    { currency: 'GTQ', currency_symbol: 'Q', multiplier: 100, type: 'STRIPE' },
    { currency: 'GYD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'HKD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'HNL', currency_symbol: 'L', multiplier: 100, type: 'STRIPE' },
    { currency: 'HRK', currency_symbol: 'kn', multiplier: 100, type: 'STRIPE' },
    { currency: 'HTG', currency_symbol: 'G', multiplier: 100, type: 'STRIPE' },
    { currency: 'HUF', currency_symbol: 'Ft', multiplier: 100, type: 'STRIPE' },
    { currency: 'IDR', currency_symbol: 'Rp', multiplier: 100, type: 'STRIPE' },
    { currency: 'ILS', currency_symbol: 'ع.د', multiplier: 100, type: 'STRIPE' },
    { currency: 'INR', currency_symbol: '₹', multiplier: 100, type: 'STRIPE' },
    { currency: 'ISK', currency_symbol: 'kr', multiplier: 100, type: 'STRIPE' },
    { currency: 'JMD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'JPY', currency_symbol: '¥', multiplier: 1, type: 'STRIPE' },
    { currency: 'KES', currency_symbol: 'Sh', multiplier: 100, type: 'STRIPE' },
    { currency: 'KGS', currency_symbol: 'с', multiplier: 100, type: 'STRIPE' },
    { currency: 'KHR', currency_symbol: '៛', multiplier: 100, type: 'STRIPE' },
    { currency: 'KMF', currency_symbol: 'Fr', multiplier: 1, type: 'STRIPE' },
    { currency: 'KRW', currency_symbol: '₩', multiplier: 1, type: 'STRIPE' },
    { currency: 'KYD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'KZT', currency_symbol: '₸', multiplier: 100, type: 'STRIPE' },
    { currency: 'LAK', currency_symbol: '₭', multiplier: 100, type: 'STRIPE' },
    { currency: 'LBP', currency_symbol: 'ل.ل', multiplier: 100, type: 'STRIPE' },
    { currency: 'LKR', currency_symbol: 'Rs', multiplier: 100, type: 'STRIPE' },
    { currency: 'LRD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'LSL', currency_symbol: 'L', multiplier: 100, type: 'STRIPE' },
    { currency: 'MAD', currency_symbol: 'د.م.', multiplier: 100, type: 'STRIPE' },
    { currency: 'MDL', currency_symbol: 'L', multiplier: 100, type: 'STRIPE' },
    { currency: 'MGA', currency_symbol: 'Ar', multiplier: 1, type: 'STRIPE' },
    { currency: 'MKD', currency_symbol: 'ден', multiplier: 100, type: 'STRIPE' },
    { currency: 'MMK', currency_symbol: 'Ks', multiplier: 100, type: 'STRIPE' },
    { currency: 'MNT', currency_symbol: '₮', multiplier: 100, type: 'STRIPE' },
    { currency: 'MOP', currency_symbol: 'P', multiplier: 100, type: 'STRIPE' },
    { currency: 'MRO', currency_symbol: 'UM', multiplier: 100, type: 'STRIPE' },
    { currency: 'MUR', currency_symbol: '₨', multiplier: 100, type: 'STRIPE' },
    { currency: 'MVR', currency_symbol: '.ރ', multiplier: 100, type: 'STRIPE' },
    { currency: 'MWK', currency_symbol: 'MK', multiplier: 100, type: 'STRIPE' },
    { currency: 'MXN', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'MYR', currency_symbol: 'RM', multiplier: 100, type: 'STRIPE' },
    { currency: 'MZN', currency_symbol: 'MT', multiplier: 100, type: 'STRIPE' },
    { currency: 'NAD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'NGN', currency_symbol: '₦', multiplier: 100, type: 'STRIPE' },
    { currency: 'NIO', currency_symbol: 'C$', multiplier: 100, type: 'STRIPE' },
    { currency: 'NOK', currency_symbol: 'kr', multiplier: 100, type: 'STRIPE' },
    { currency: 'NPR', currency_symbol: '₨', multiplier: 100, type: 'STRIPE' },
    { currency: 'NZD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'PAB', currency_symbol: 'B/.', multiplier: 100, type: 'STRIPE' },
    { currency: 'PEN', currency_symbol: 'S/.', multiplier: 100, type: 'STRIPE' },
    { currency: 'PGK', currency_symbol: 'K', multiplier: 100, type: 'STRIPE' },
    { currency: 'PHP', currency_symbol: '₱', multiplier: 100, type: 'STRIPE' },
    { currency: 'PKR', currency_symbol: '₨', multiplier: 100, type: 'STRIPE' },
    { currency: 'PLN', currency_symbol: 'zł', multiplier: 100, type: 'STRIPE' },
    { currency: 'PYG', currency_symbol: '₲', multiplier: 1, type: 'STRIPE' },
    { currency: 'QAR', currency_symbol: 'ر.ق', multiplier: 100, type: 'STRIPE' },
    { currency: 'RON', currency_symbol: 'lei', multiplier: 100, type: 'STRIPE' },
    { currency: 'RSD', currency_symbol: 'дин', multiplier: 100, type: 'STRIPE' },
    { currency: 'RUB', currency_symbol: '₽', multiplier: 100, type: 'STRIPE' },
    { currency: 'RWF', currency_symbol: 'Fr', multiplier: 1, type: 'STRIPE' },
    { currency: 'SAR', currency_symbol: 'ر.س', multiplier: 100, type: 'STRIPE' },
    { currency: 'SBD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'SCR', currency_symbol: '₨', multiplier: 100, type: 'STRIPE' },
    { currency: 'SEK', currency_symbol: 'kr', multiplier: 100, type: 'STRIPE' },
    { currency: 'SGD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'SHP', currency_symbol: '£', multiplier: 100, type: 'STRIPE' },
    { currency: 'SLL', currency_symbol: 'Le', multiplier: 100, type: 'STRIPE' },
    { currency: 'SOS', currency_symbol: 'Sh', multiplier: 100, type: 'STRIPE' },
    { currency: 'SRD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'STD', currency_symbol: 'Db', multiplier: 100, type: 'STRIPE' },
    { currency: 'SZL', currency_symbol: 'L', multiplier: 100, type: 'STRIPE' },
    { currency: 'THB', currency_symbol: '฿', multiplier: 100, type: 'STRIPE' },
    { currency: 'TJS', currency_symbol: 'ЅМ', multiplier: 100, type: 'STRIPE' },
    { currency: 'TOP', currency_symbol: 'T$', multiplier: 100, type: 'STRIPE' },
    { currency: 'TRY', currency_symbol: '₺', multiplier: 100, type: 'STRIPE' },
    { currency: 'TTD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'TWD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'TZS', currency_symbol: 'Sh', multiplier: 100, type: 'STRIPE' },
    { currency: 'UAH', currency_symbol: '₴', multiplier: 100, type: 'STRIPE' },
    { currency: 'UGX', currency_symbol: 'Sh', multiplier: 1, type: 'STRIPE' },
    { currency: 'UYU', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'UZS', currency_symbol: 'сўм', multiplier: 100, type: 'STRIPE' },
    { currency: 'VND', currency_symbol: '₫', multiplier: 1, type: 'STRIPE' },
    { currency: 'VUV', currency_symbol: 'Vt', multiplier: 1, type: 'STRIPE' },
    { currency: 'WST', currency_symbol: 'T', multiplier: 100, type: 'STRIPE' },
    { currency: 'XAF', currency_symbol: 'Fr', multiplier: 1, type: 'STRIPE' },
    { currency: 'XCD', currency_symbol: '$', multiplier: 100, type: 'STRIPE' },
    { currency: 'XOF', currency_symbol: 'Fr', multiplier: 1, type: 'STRIPE' },
    { currency: 'XPF', currency_symbol: '₣', multiplier: 1, type: 'STRIPE' },
    { currency: 'YER', currency_symbol: '﷼', multiplier: 100, type: 'STRIPE' },
    { currency: 'ZAR', currency_symbol: 'Rs', multiplier: 100, type: 'STRIPE' },
    { currency: 'ZMW', currency_symbol: 'ZK', multiplier: 100, type: 'STRIPE' }
  ]
  
  export const paypalCurrencies = [
    { currency: 'AUD', currency_symbol: '$', type: 'PAYPAL' },
    { currency: 'BRL', currency_symbol: 'R$', type: 'PAYPAL' },
    { currency: 'CAD', currency_symbol: '$', type: 'PAYPAL' },
    { currency: 'CZK', currency_symbol: 'Kč', type: 'PAYPAL' },
    { currency: 'DKK', currency_symbol: 'kr', type: 'PAYPAL' },
    { currency: 'EUR', currency_symbol: '€', type: 'PAYPAL' },
    { currency: 'HKD', currency_symbol: '$', type: 'PAYPAL' },
    { currency: 'HUF', currency_symbol: 'Ft', type: 'PAYPAL' },
    { currency: 'INR', currency_symbol: '₹', type: 'PAYPAL' },
    { currency: 'ILS', currency_symbol: 'ع.د', type: 'PAYPAL' },
    { currency: 'JPY', currency_symbol: '¥', type: 'PAYPAL' },
    { currency: 'MYR', currency_symbol: 'RM', type: 'PAYPAL' },
    { currency: 'MXN', currency_symbol: '$', type: 'PAYPAL' },
    { currency: 'TWD', currency_symbol: '$', type: 'PAYPAL' },
    { currency: 'NZD', currency_symbol: '$', type: 'PAYPAL' },
    { currency: 'NOK', currency_symbol: 'kr', type: 'PAYPAL' },
    { currency: 'PHP', currency_symbol: '₱', type: 'PAYPAL' },
    { currency: 'PLN', currency_symbol: 'zł', type: 'PAYPAL' },
    { currency: 'GBP', currency_symbol: '£', type: 'PAYPAL' },
    { currency: 'RUB', currency_symbol: '₽', type: 'PAYPAL' },
    { currency: 'SGD', currency_symbol: '$', type: 'PAYPAL' },
    { currency: 'SEK', currency_symbol: 'kr', type: 'PAYPAL' },
    { currency: 'CHF', currency_symbol: 'Fr', type: 'PAYPAL' },
    { currency: 'THB', currency_symbol: '฿', type: 'PAYPAL' },
    { currency: 'USD', currency_symbol: '$', type: 'PAYPAL' }
  ]
  